<template>
  <div>
    <v-data-table
      v-model="TDselected"
      :headers="headers"
      :items="desserts"
      class="elevation-0"
      :search="search"
      item-key="application_id"
      show-select
      :loading="loadingstatus"
      loading-text="Loading... Please wait"
      style="border-radius: 0px"
    >
      <template v-slot:top>
        <v-card
          flat          
          style="position: sticky; top: 0px; z-index: 900"
        >
          <v-card-actions class="pa-0">
            <v-row v-if="TDselected.length > 0 && status_id != 7">
              <v-col cols="12" md="3" class="py-0">
                <v-select
                  label="Select Status"
                  v-model="SelstatusForUpade"
                  :items="StatusForUpdate"
                  item-text="status_name"
                  item-value="status_id"
                  dense
                  outlined
                  hide-details
                  @change="getreson"
                ></v-select>
              </v-col>
              <v-col cols="12" md="5" class="py-0" v-if="strcheckatatus != 3">
                <v-select
                  label="Select Reason"
                  v-model="SelReasonForUpdate"
                  :items="ResonForUpdate"
                  item-text="reason_name"
                  item-value="reason_id"
                  dense
                  outlined
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" md="5" class="py-0" v-if="strcheckatatus == 3">
                <v-select
                  label="Select Card Type"
                  v-model="SelCardTypeForUpdate"
                  :items="CardTypeForUpdate"
                  item-text="card_name"
                  item-value="card_id"
                  class=""
                  dense
                  outlined
                  hide-details
                ></v-select>
              </v-col>

              <v-col cols="12" md="3" class="py-0">
                <v-btn
                  medium
                  color="primary"
                  @click="btnsave"
                  class="fullwidth"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                dense
                solo-inverted
                flat
                label="Search"
                hide-details
              ></v-text-field>
          </v-card-actions>
        </v-card>
      </template>
      <!-- @click="BtnApprove(index)" -->

      <template v-slot:item.application_id="{ item }">
        <tr @click="BtnApprove(item.s_no, item.status_id)">
          <!-- <td>
            <v-checkbox
              v-model="chkselected"
              :value="item.application_id"
              @click="chkclick"
            ></v-checkbox>
          </td> -->

          <td style="text-align: left; width: 50px">
            {{ item.application_id }}
          </td>
          <td style="text-align: left; width: 65px">
            <img
              :src="$apiPath + item.picHeadShot"
              height="60px"
              width="60px"
              style="border-radius: 10px; border: 1px solid #ccc"
              type="button"
            />
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
        No Record(s)
      </template>
    </v-data-table>

    <v-dialog v-model="isViewAppOpened" fullscreen style="z-index: 1000">
      <v-card flat style="background-color: rgb(233 233 233)">
        <!-- <v-btn
          large
          outlined
          icon
          color="error"
          class="ma-5"
          @click="isViewAppOpened = false"
          style="top: 10px; right: 10px; z-index: 1001; position: fixed"
          ><v-icon>mdi-close</v-icon></v-btn
        > -->


        <v-toolbar
          dark
          color="primary"
         dense
        >
         
           <v-toolbar-title>Application ID : {{cApp.application_id}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
       

          <v-btn
            icon
            dark
           @click="isViewAppOpened = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          </v-toolbar-items>
        </v-toolbar>

        <ViewApplication :appData="cApp" :appstatus="appstatus" :appreasonlist="ResonForUpdate">
        </ViewApplication>

        <v-footer
          class="mt-1 pa-3"
          style="
            bottom: 0px;
            left: 0px;
            z-index: 1001;
            position: fixed;
            width: 100%;
          "
        >
          <v-btn medium color="primary" @click="appPrevious" class="ml-1">
            Previous
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn medium color="primary" @click="appNext" class="ml-1">
            Next
          </v-btn>
        </v-footer>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar"  top :color="snackbarcolor">
      {{ snackbartext }}
      
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
import axios from "axios";
//import moment from "moment";
import ViewApplication from "../../components/Admin/ViewApplicationPkl.vue";
export default {
  components: {
    ViewApplication,
  },
  data() {
    return {
      status_id: "",
      TDselected: [],
      company_name: "",
      singleSelect: false,
      selected: [],
      chkselected: [],
      cApp: {},
      appstatus: "none",
      cAppIndex: 0,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      isViewAppOpened: false,
      modal2: false,
      modal: false,
      overlay: false,
      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: true,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",

      Selstatus: "",
      Status: [],

      SelstatusForUpade: "",
      // StatusForUpdate: [],

      SelstatusForUpdate: "",
      StatusForUpdate: [
        {
          status_id: 3,
          status_name: "Approve",
          status: "O",
          status_named: "Approved",
        },
        {
          status_id: 4,
          status_name: "Amend",
          status: "O",
          status_named: "Amended",
        },

        {
          status_id: 6,
          status_name: "Reject",
          status: "O",
          status_named: "Rejected",
        },
      ],

      SelReasonForUpdate: "",
      ResonForUpdate: [],
      strcheckatatus: "",

      SelCardTypeForUpdate: "",
      CardTypeForUpdate: [],

      SelCompany: "",
      Company: [],

      //companytype: [],
      companytype_id: [],
      postBody: {
        company_name: "",
        event_id: "",
        venue_id: "",
        vendor_name: "",
        vendor_email: "",
        no_of_application: "",
        deadline: "",
        companytype_id: [],
        Vendor_ID_Update: "",
        uflag: "0",
        FlagCompanyName: "",
        FlagEmail: "",
      },

      postBodyBulk: {
        event_id: "",
        venue_id: "",
        deadline: "",
      },
      postBulkUpdate: {
        application_id: [],
        event_id: "",
        venue_id: "",
        vendor_id: "",
        status_id: "",
        reason_id: "",
        card_id: "",
      },
      headers: [
        {
          text: "App ID",
          align: "left",
          value: "application_id",
          class: "blue lighten-5",
          sortable: false,
        },

        {
          text: "Name",
          value: "fName",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Designation",
          value: "jobtype_name",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "Zone",
          value: "print_text",
          align: "left",
          class: "blue lighten-5",
        },

        {
          text: "App Status",
          value: "status_named",
          align: "left",
          class: "blue lighten-5",
        },
        {
          text: "Remarks",
          value: "reason_name",
          align: "left",
          class: "blue lighten-5",
        },
      ],
      desserts: [],
      Vcompany_name: [
        (v) => !!v || "Company Name is required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vdatebulk: [
        (v) => !!v || "Date required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      Vtimebulk: [
        (v) => !!v || "Time required",
        // v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    };
  },
  mounted() {
    this.company_name = sessionStorage.COMPANYNAME;
    // sessionStorage.display_event_name = "IPL T20 2021";
    // sessionStorage.display_venue_name = "Chinaswamy Stadium";

    // sessionStorage.EVENTID = "1";
    // sessionStorage.VENUEID = "1";
    // sessionStorage.VENDORID = "1";

    this.$store.commit("setPageTitle", "Application Summary Detail");
   // this.$store.commit("setEventName", sessionStorage.display_event_name);
    //this.$store.commit("setVenueName", sessionStorage.display_venue_name);
    this.status_id = sessionStorage.STATUSID;
    this.Reload(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId,
      sessionStorage.COMPANYID,
      sessionStorage.STATUSID
    );

    this.bindReson(sessionStorage.STATUSID);

    //  this.bindStatus();
    this.bindCartype(this.$store.state.eventInfo.EventId);
    this.bindCompany(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId
    );
    //  this.bindReson("1");
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

    

    getreson: function () {
     

      this.bindReson(this.SelstatusForUpade);

      this.strcheckatatus = this.SelstatusForUpade;
    },
    chkclick: function () {
      if (this.chkselected.length > 0) {
        this.sheet = true;
      } else {
        this.sheet = false;
      }
    },

    btnclose: function () {
      this.TDselected = "";
      this.chkselected = [];
      this.sheet = false;
    },

    BtnApprove: function (ID, status_ID) {
      //alert(ID);

      this.cAppIndex = ID - 1;
      this.cApp = this.desserts[this.cAppIndex];
      this.cApp.dob = this.cApp.dob.slice(0, 10);
      this.appstatus = status_ID;
      this.isViewAppOpened = true;
    },

    btn_reload: function () {
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId
      );
    },

    btnsearch: function () {
      this.sheet = false;
      this.desserts = [];
      this.chkselected = [];
      this.appstatus = this.Selstatus;
      this.Reload(
        this.$store.state.eventInfo.EventId,
        this.$store.state.eventInfo.VenueId,
        this.SelCompany,
        this.Selstatus
      );
    },

    appNext: function () {
      if (this.cAppIndex < this.desserts.length - 1) {
        this.cAppIndex = this.cAppIndex + 1;
        this.cApp = this.desserts[this.cAppIndex];
        this.bindReson(this.cApp.status_id);
      }
    },
    appPrevious: function () {
      if (this.cAppIndex > 0) {
        this.cAppIndex = this.cAppIndex - 1;
        this.cApp = this.desserts[this.cAppIndex];
        this.bindReson(this.cApp.status_id);
      }
    },

    bindCompany: function (EventID, VenueID) {
      axios
        .get(this.$apiPath + "vendor/GetByEventId/" + EventID + "/" + VenueID, {
          headers: { Appkey: this.$aKey },
        })
        .then((res) => {
          this.Company = res.data.result;
        });
    },

    bindStatus: function () {
      axios
        .get(this.$apiPath + "Status", {
          headers: { Appkey: this.$aKey },
        })
        .then((res) => {
          this.Status = res.data.result;
          this.StatusForUpdate = res.data.result;
        });
    },

    bindCartype: function (event_id) {
      axios
        .get(this.$apiPath + "Cards/GetByEventId/" + event_id, {
          headers: { Appkey: this.$aKey },
        })
        .then((res) => {
          this.CardTypeForUpdate = res.data.result;
        });
    },

    bindReson: function (StatusID) {
      axios
        .get(this.$apiPath + "Reason/GetByStatusID/" + StatusID, {
          headers: { Appkey: this.$aKey },
        })
        .then((res) => {
          this.ResonForUpdate = res.data.result;
        });
    },

    Reload: function (event_id, venue_id, company_id, status) {
     // alert(company_id);
      this.desserts=[];
      this.loadingstatus = true;
      axios
        .get(
          this.$apiPath +
            "Application/GetByAppStatus/" +
            event_id +
            "/" +
            venue_id +
            "/" +
            company_id +
            "/" +
            status,
          {
            headers: { Appkey: this.$aKey },
          }
        )
        .then((res) => {
          this.desserts = res.data.result;

          this.loadingstatus = false;
          this.TDselected=[];
          this.postBulkUpdate.application_id=[];

        })
        .catch()
        .finally();
    },
    New() {
      this.EntryMode = "New Vendor";
      //  this.postBody.event_name = "";
      this.CleareData();
      this.sheet = true;
    },

    btnsave: function () {

     
      if (this.SelstatusForUpade.length <= 0) {
        this.showSnackbar("error", "Select Status !");
        return;
      }

      if (this.SelstatusForUpade == "3") {
        if (this.SelCardTypeForUpdate.length <= 0) {
          this.showSnackbar("error", "Select Card !");
          return;
        }
      }

      if (this.SelstatusForUpade != "3") {
        if (this.SelReasonForUpdate.length <= 0) {
          this.showSnackbar("error", "Select Reason !");
          return;
        }
      }

      this.TDselected.forEach((el) => {
        this.postBulkUpdate.application_id.push(el.application_id);
      });

      this.postBulkUpdate.event_id = this.$store.state.eventInfo.EventId;
      this.postBulkUpdate.venue_id = this.$store.state.eventInfo.VenueId;
      this.postBulkUpdate.vendor_id = sessionStorage.COMPANYID;
      this.postBulkUpdate.status_id = this.SelstatusForUpade;

      if (this.SelstatusForUpade == "3") {
        this.postBulkUpdate.reason_id = 0;
        this.postBulkUpdate.card_id = this.SelCardTypeForUpdate;
      } else {
        this.postBulkUpdate.card_id = "0";
        this.postBulkUpdate.reason_id = this.SelReasonForUpdate;
      }

      //       if(this.SelReasonForUpdate==""){
      // this.postBulkUpdate.reason_id ="0";
      //       }
      //       else
      //       {
      // this.postBulkUpdate.reason_id = this.SelReasonForUpdate;
      //       }

      //        if(this.SelCardTypeForUpdate==""){
      // this.postBulkUpdate.card_id ="0";
      //       }
      //       else
      //       {
      // this.postBulkUpdate.card_id = this.SelCardTypeForUpdate;
      //       }

      // this.postBulkUpdate.application_id = this.desserts.application_id;
    

      axios
        .put(
          this.$apiPath + "Application/ChangeMultiAppStatus",
          this.postBulkUpdate,
          {
            headers: { Appkey: this.$aKey },
          }
        )
        .then((res) => {
          if (res.data.status == "Success") {
            // alert("data updated");
            this.showSnackbar("success", "Data Updated !");
            // this.Reload(sessionStorage.EVENTID, sessionStorage.VENUEID);
           // this.TDselected="";
            // this.postBulkUpdate.application_id="";
            this.TDselected=[];
            this.postBulkUpdate.application_id=[];
            //this.postBulkUpdate.application_id="";
          //  this.postBulkUpdate.application_id.CleareData();
          
            this.Reload(
              this.$store.state.eventInfo.EventId,
              this.$store.state.eventInfo.VenueId,
              sessionStorage.COMPANYID,
              sessionStorage.STATUSID
            );
          }
        })
        .catch()
        .finally();
    },

    close() {
      this.sheet = false;
      //  this.Reload();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style>
</style>